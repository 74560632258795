.navbar {
  /* spacing between top navigation items*/
  --bs-navbar-nav-link-padding-x: 25px !important;
  margin-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.nav-link {
  font-weight: normal !important;
}

/* to keep the hamburger next to right border*/
@media ((max-width: 992px)) {
  .container {
    max-width: 100% !important;
    padding-bottom: 0px;
  }
}

/* some padding on botton when hamburger drops below brand logo */
@media ((max-width: 456px)) {
  .container {
    padding-bottom: 6px;
  }
}
