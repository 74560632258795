.breadcrumb {
  background-color: #404040 !important;
  height: 50px;
  padding: 0.75rem 1rem !important;
}

.breadcrumb-item > a {
  text-decoration: none !important;
}

/* no decoration on menu links */
.navbar-nav > a:hover {
  text-decoration: none !important;
}

.text-white > a {
  color: white !important;
}

li::before {
  color: gray !important;
}
