.carousel-image {
  width: 100%;
  border-radius: 15px !important;
  /* cursor: default !important; */
}

#m5-card-image {
  border-radius: 4px !important;
  overflow: hidden !important;
}

.card-unselected {
  border-width: 0px !important;
  padding: 6px;
}

.card-selected {
  border-width: 3px !important;
  padding: 3px;
  border-color: #666666;
}

.product-id {
  color: #666666;
  font-weight: normal;
}

.product-price {
  color: #555555;
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 6px;
}

.quantity {
  width: 70px;
  padding-bottom: 8px;
}
