.m5controlsystems-heading {
  color: #555555;
}

.m5controlsystems-text {
  color: #666666; 
  font-size: 16px;
}

.m5controlsystems-content {
  width: 80% !important;
}

.m5controlsystems-card-text {
  color: #666666;
  font-size: 14px;
}

.no-border {
  border: none !important;
}

.fixed-width-button {
  width: 130px; /* Set your desired width */
}

/* remove 80% width when we are on small devices */
@media ((max-width: 992px)) {
  .m5controlsystems-content {
    width: 100% !important;
  }
}
